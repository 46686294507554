import { BannerProps } from '@codecademy/brand';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCookie } from 'react-use';

import { trackUserClick } from '~/libs/eventTracking';
import { selectBrandedBanner } from '~/state/brandedBanner/selectors';

export const bannerLabel = 'branded-banner';

const getBrandedBannerCookieId = (id: string) => `hide_branded_banner-${id}`;

export const useBrandedBanner = (): BannerProps | undefined => {
  const { backgroundColor, content, id } =
    useSelector(selectBrandedBanner) || {};

  const [brandedBannerCookieValue, updateBrandedBannerCookie] = useCookie(
    getBrandedBannerCookieId(id)
  );
  const hideBrandedBanner =
    typeof window !== 'undefined' && brandedBannerCookieValue;
  const [dismissedBanner, setDismissedBanner] = useState(
    hideBrandedBanner === 'true'
  );

  if (!content || dismissedBanner) {
    return undefined;
  }

  return {
    variant: backgroundColor,
    id: bannerLabel,
    role: 'complementary',
    'aria-label': bannerLabel,
    onClose: () => {
      updateBrandedBannerCookie('true');
      setDismissedBanner(true);
      trackUserClick({
        target: 'branded_banner_closed',
        context: id,
      });
    },
    onCtaClick: () =>
      trackUserClick({
        target: 'branded_banner_cta',
        context: id,
      }),
    children: content,
  };
};
